import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import { useCart } from "../context/Cart";
import toast from "react-hot-toast";
import { baseURL ,Custom_jacket } from "../constants/url";
import parse from 'html-react-parser';
import { Carousel } from 'react-responsive-carousel';
import Loader from '../components/Loader'

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [cart, setCart] = useCart();
  const [selectSize , setSelectSize] = useState('')
  const [loading , setLoading] = useState(false)
  //getProduct
  const getProduct = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      getSimilarProduct(data.product.category._id)
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  const checkCart = (pid) =>{
         const productexist = cart.filter((c) => c._id === pid).length === 0 ? false : true
         console.log(productexist, "exists")
         return productexist
  }
  //get similar product
  const getSimilarProduct = async (category) => {
    try {
      const { data } = await axios.get(
        `/api/v1/product/product-filters?category=${category}&limit=4`
      );
      setRelatedProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  //initalp details
  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div>
    {loading ? <Loader/> :(
      
    <Layout>
      <div className="row container pb-5">
       <div className="col-md-6 d-flex justify-content-center align-items-center">
           <div>
            {product.otherImages && (
            <Carousel showStatus={false} showArrows={false}  interval={5000}>
              <div>
            <img
              src={product?.frontImage}
              className="product-img"
              alt={product?.imgAlt}
            />
            </div>
            {product && [...product?.otherImages].map((p, index) => (
                <div key={index}> 
            <img
              src={`${p}`}
              className="product-img"
              alt={product?.imgAlt}
            />
            </div>
          ))}
            </Carousel>
            )}
          </div> 
        </div>
        <div className="col-md-6 mt-3 text-left">
          <h3 className="text-center mb-4">{product?.name?.toUpperCase()}</h3>
          <p>{parse(`${product?.description}`)}</p>
          <h3><span className="font-bold">$</span>{selectSize ? product?.sizes.filter((s, i) => s?.size === selectSize)[0]?.price : product?.standardPrice}</h3>
          <div className="container my-5 col-md-12 text-left">
        <label for="Size" className="form-label">Size</label>
        <select className="form-select" id="Size" onChange={(e) => setSelectSize(e.target.value)}>
           {product?.sizes?.map((s, i) =>(
            <option value={s?.size} key={i}  style={{ width : "50px"}}>{s?.size}</option>
          ))}
        </select>
       </div>
          
          <button
            className="btn btn-primary btn-lg w-100"
            onClick={() => {
              if(checkCart(product?._id)){
                console.log(checkCart(product?._id))
                toast.error("Item Already exists");
              }
              else{
                if(product){
                const cartData = JSON.parse(sessionStorage.getItem("cart")) ? JSON.parse(sessionStorage.getItem("cart")) : []
                const cartSize = [...product?.sizes]
                const sizedetail = cartSize.filter((s, i) => s?.size == selectSize)
                sessionStorage.setItem("cart", JSON.stringify([...cartData, {...product , size : sizedetail[0]?.size ? sizedetail[0]?.size : product.sizes[0]?.size , quantity : 1 , price: sizedetail[0]?.price ? sizedetail[0]?.price : product.sizes[0]?.price ,custom : false}]))
                setCart(JSON.parse(sessionStorage.getItem("cart")));
                toast.success("Item Added to cart");
                }
              }
            }}
          >
            ADD TO CART
          </button>
          <button
            className="btn btn-lg w-100"
            style={{
              background: "linear-gradient(135deg, #6b73ff 0%, #000dff 100%)",
              color: "#ffffff",
              marginTop: "2%",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.target.style.background =
                "linear-gradient(135deg, #5a63e0 0%, #000bd9 100%)";
              e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
            }}
            onMouseOut={(e) => {
              e.target.style.background =
                "linear-gradient(135deg, #6b73ff 0%, #000dff 100%)";
              e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
            }}
          >
            <a
              href={product.designId ? `${Custom_jacket}?id=${product?.category?.code}&design=${product?.designId}` : `${Custom_jacket}?id=${product?.category?.code}`}
              style={{
                textDecoration: "none",
                color: "white",
                font: "bold",
                fontWeight: 400,
                background: "linear-gradient(135deg, #6b73ff 0%, #000dff 100%)",
                border: "none",
              }}
            >
              ADD PATCHES
            </a>
          </button>
        </div>

      </div>
            

      <hr />
      <div className="row container similar-products">
        <h4>Similar Products ➡️</h4>
        {relatedProducts.length === 0 && (  
          <p className="text-center">No Similar Products found</p>
        )}
        <div className="w-100 product-styling">
          {relatedProducts?.map((p) => (
            <div className="card m-2" key={p?._id}>
              <img
                src={p.frontImage}
                className="card-img-top"
                alt={p?.imgAlt}
              />
              <div className="card-body">
                <div className="card-name-price">
                  <h5 className="card-title">{p.name}</h5>
                  <h5 className="card-title card-price">
                    {p.standardPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </h5>
                </div>
                <div className="card-name-price">
                  <button
                    className="btn btn-info ms-1"
                    onClick={() => navigate(`/product/${p?._id}`)}
                  >
                    More Details
                  </button>
                  {/* <button
                  className="btn btn-dark ms-1"
                  onClick={() => {
                    setCart([...cart, p]);
                    sessionStorage.setItem(
                      "cart",
                      JSON.stringify([...cart, p])
                    );
                    toast.success("Item Added to cart");
                  }}
                >
                  ADD TO CART
                </button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      
    </Layout>
    
)}
</div>
  );
};

export default ProductDetails;
