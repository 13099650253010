import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "../components/layout/Layout";

export const BulkOrders = () => {
  const [selectedProduct, setSelectedProduct] = useState("Hoodies");
  const [zipoutLining, setZipoutLining] = useState(false);
  const [flapClosure, setFlapClosure] = useState(false);
  const [designLocations, setDesignLocations] = useState({
    frontCenter: false,
    rightChest: false,
    leftChest: false,
    rightPocket: false,
    leftPocket: false,
    rightSleeve: false,
    leftSleeve: false,
    rightCuff: false,
    leftCuff: false,
    backTop: false,
    backMiddle: false,
    backBottom: false,
    nickName: false,
  });
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleLocation = (location) => {
    setDesignLocations((prev) => ({
      ...prev,
      [location]: !prev[location],
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100); // Add a slight delay for animation
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        {/* Heading */}
        <div className={`mt-5 py-5 ${isVisible ? "fade-in" : ""}`}>
          <h1 className="fs-1 fw-bold" style={{ fontSize: "90px" }}>
            Get Free Bulk Orders Quote
          </h1>
          <p>
            Easy Jackets is a leading manufacturer and supplier of custom varsity jackets, letterman jackets, bomber jackets, and hoodies. We supply custom jackets made of melton wool, cowhide leather, cotton fleece, and satin to the USA, Canada, and the rest of the world.
            <br />
            <br />
            Looking for custom varsity jackets for high schools, seniors class of 2020-2021, dance clubs, football and baseball teams? You are at the right place. We produce premium quality, affordable custom jackets. Our turnaround time is fast compared to others. We offer a huge discount on bulk/team orders depending upon the quantity and customizations on a garment. You can use the form below to specify your material requirements and customization on the custom seniors jackets, bomber jackets, hoodies, and sweatshirts. We usually require 2-4 weeks to produce 15-50 jackets depending upon the customization on a garment.
          </p>
        </div>

        {/* Product Selection */}
        <div className={`d-flex justify-content-center ${isVisible ? "fade-in" : ""}`}>
          <div className="w-50 mt-5">
            <div className="row">
              <div className="col-md-6 mb-3 text-uppercase fw-bold text-secondary">
                <label>Select Product:</label>
                <select
                  className="form-select"
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="Varsity Jacket">Varsity Jacket</option>
                  <option value="Bomber Jacket">Bomber Jacket</option>
                  <option value="Hoodies">Hoodies</option>
                </select>
              </div>

              <div className="col-md-6 mb-3 text-uppercase fw-bold text-secondary">
                <label>Product Quantities (Minimum 10):</label>
                <input type="number" className="form-control" min="10" />
              </div>

              <div className="col-md-6 mb-3 text-uppercase fw-bold text-secondary">
                <label>Select Product:</label>
                <select
                  className="form-select"
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="Quilt">Varsity Jacket</option>
                  <option value="Satin">Bomber Jacket</option>
                </select>
              </div>

              <div className="col-md-6 mb-3 text-uppercase fw-bold text-secondary">
                <label>Add 1/2 Zipout Lining:</label>
                <div className="btn-group d-flex">
                  <button
                    onClick={() => setZipoutLining(true)}
                    className={`btn btn-sm ${zipoutLining ? "btn-primary" : "btn-outline-secondary"} w-50`}
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setZipoutLining(false)}
                    className={`btn btn-sm ${!zipoutLining ? "btn-primary" : "btn-outline-secondary"} w-50`}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className="col-md-6 mb-3 text-uppercase fw-bold text-secondary">
                <label>FRONT CLOSURE:</label>
                <select
                  className="form-select"
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="Buttons">Varsity Jacket</option>
                  <option value="Zipper">Bomber Jacket</option>
                </select>
              </div>

              <div className="col-md-6 mb-3 text-uppercase fw-bold text-secondary">
                <label>Add Flap Closure:</label>
                <div className="btn-group d-flex">
                  <button
                    onClick={() => setFlapClosure(true)}
                    className={`btn btn-sm ${flapClosure ? "btn-primary" : "btn-outline-secondary"} w-50`}
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setFlapClosure(false)}
                    className={`btn btn-sm ${!flapClosure ? "btn-primary" : "btn-outline-secondary"} w-50`}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>

            {/* Design Location Selection */}
            <h3 className={`mb-4 ${isVisible ? "fade-in" : ""}`}>Select Custom Design Locations</h3>
            <div className={`row mb-5 ${isVisible ? "fade-in" : ""}`}>
              {Object.keys(designLocations).map((location) => (
                <div className="col-6 col-md-3 mb-3 text-uppercase fw-bold text-secondary" key={location}>
                  <label className="form-label">{location.replace(/([A-Z])/g, " $1")}</label>
                  <div className="btn-group d-flex">
                    <button
                      onClick={() => handleToggleLocation(location)}
                      className={`btn btn-sm ${designLocations[location] ? "btn-primary" : "btn-outline-secondary"} w-50`}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => handleToggleLocation(location)}
                      className={`btn btn-sm ${!designLocations[location] ? "btn-primary" : "btn-outline-secondary"} w-50`}
                    >
                      No
                    </button>
                  </div>
                </div>
              ))}

              {/* Upload Button spanning across 2 columns */}
              <div className="col-12 col-md-6 mb-3">
                <h6 className="text-uppercase fw-bold text-secondary">Upload Your Design</h6>
                <input type="file" className="form-control w-100" />
              </div>
            </div>

            {/* Contact Information */}
            <div className="mb-5">
              <h2 className={`mb-4 text-start fw-bold ${isVisible ? "fade-in" : ""}`}>Contact Information</h2>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Name:</label>
                  <input type="text" className="form-control" placeholder="Please enter your full name" />
                </div>

                <div className="col-md-6 mb-3">
                  <label>Email:</label>
                  <input type="email" className="form-control" placeholder="Please enter email address" />
                </div>

                <div className="col-md-6 mb-3">
                  <label>Phone:</label>
                  <input type="text" className="form-control" placeholder="Please enter your contact number" />
                </div>

                <div className="col-md-6 mb-3">
                  <label>Country:</label>
                  <input type="text" className="form-control" placeholder="Please enter your country" />
                </div>

                <div className="col-12 mb-3">
                  <label>Message:</label>
                  <textarea className="form-control" rows="3" placeholder="Your message"></textarea>
                </div>
              </div>
              <button className="btn btn-warning">Get Free Quote</button>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fade-in {
          opacity: 0;
          animation: fadeIn 0.5s forwards;
        }

        @keyframes fadeIn {
          to {
            opacity: 1;
          }
        }
      `}</style>
    </Layout>
  );
};

export default BulkOrders;
