import React from 'react'
import { Link } from 'react-router-dom'

const PaymentCancel = () => {
  return (
    <div className='text-center'>
    <h2>Payment Cancelled</h2>
    <div className='my-4'>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
</svg>
  </div >
    <Link to="/cart">GO Back to CART {"->"}</Link>
    </div>
  )
}

export default PaymentCancel